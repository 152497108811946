<template>
  <v-row>
    <v-col cols="12">
      <p>
        FL比率の推移と目標FL値との比較ができます。
        <router-link to="/settings/fl-ratio">
          目標FL比率を設定ページ
        </router-link>
        より設定して下さい。棒グラフは指でタップすると比率詳細を表示することができます。
      </p>
      <p>
        設定した目標FL値が赤い点線になり、その点線よりも食材費や人件費が下回ることが理想です。
      </p>
      <p>
        ※freeeと本アプリを連携している方はCP事務局で勘定科目を食材費・人件費の仕分け設定を行います。
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FLRatioTransitionModal"
};
</script>

<style scoped></style>
